import { Route, Routes } from 'react-router-dom';
import SharedLayout from './SharedLayout';
import { lazy } from 'react';

const MainPage = lazy(() => import('pages/MainPage'));

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SharedLayout />}>
        <Route index element={<MainPage />} />
      </Route>
    </Routes>
  );
};
