import Icon from 'components/Icon';
import { FooterWrapper, IconWrapper } from './Footer.styled';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <FooterWrapper>
      <IconWrapper>
        <NavLink
          to={`https://www.facebook.com/zagoriyfoundation/`}
          target="blank"
        >
          <Icon iconName="icon-facebook" width="24" height="24" />
        </NavLink>
      </IconWrapper>
      <IconWrapper>
        <NavLink
          to={`https://www.instagram.com/zagoriy.foundation/`}
          target="blank"
        >
          <Icon iconName="icon-instagram" width="24" height="24" />
        </NavLink>
      </IconWrapper>
      <IconWrapper>
        <NavLink
          to={`https://www.facebook.com/zagoriyfoundation/`}
          target="blank"
        >
          <Icon iconName="icon-twitter" width="24" height="24" />
        </NavLink>
      </IconWrapper>
      <IconWrapper>
        <NavLink to={`https://twitter.com/ZagoriyFdn`} target="blank">
          <Icon iconName="icon-linkedin" width="24" height="24" />
        </NavLink>
      </IconWrapper>
    </FooterWrapper>
  );
};

export default Footer;
