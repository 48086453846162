import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const Wrapper = styled.header`
  position: relative;
  background-color: white;
  min-height: 70px;
  border-bottom: 1px solid grey;
`;

export const SHimg = styled.img`
  position: absolute;

  animation: ${pulse} 1s infinite;
`;

export const Logo = styled.img`
  position: absolute;
  top: 0;
  right: 32px;
  transition: all 300ms linear;

  &:hover,
  &:focus {
    scale: 1.1;
  }
`;
