import { Logo, SHimg, Wrapper } from './Header.styled';
import SH from 'assets/images/SH.png';
import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Wrapper>
      <SHimg src={SH} alt="Strong Hearts Logo" width={240} height={150} />
      <Link to="https://darnitsa.ua" target="_blank">
        <Logo src={logo} alt="company logo" width={210} height={63} />
      </Link>
    </Wrapper>
  );
};

export default Header;
