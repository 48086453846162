import styled from 'styled-components';
import { selectDesktop, selectPhone, selectTablet } from 'utils/selectMediaReq';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`;

export const MainContainer = styled.main`
  width: 100%;
  margin: 0 auto;
  padding: 80px 12px 20px 12px;
  align-self: center;

  @media ${selectPhone} {
    min-width: 480px;
  }

  @media ${selectTablet} {
    padding: 80px 24px 20px 24px;
    width: 768px;
  }

  @media ${selectDesktop} {
    width: 1280px;
    padding: 40px 32px 32px 32px;
  }
`;
