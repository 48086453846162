const media = {
  phone: '(min-width: 480px)',
  tablet: '(min-width: 768px)',
  desktop: '(min-width: 1280px)',
};

const colors = {
  primary: 'rgba(232, 22, 37, 1)',
  secondary: 'rgba(0, 152, 121, 1)',
  bgPrimary: 'rgba(214, 250, 236, 1)',
  bgSecondary: 'rgba(254, 227, 182, 1)',
  bgMeta: '	rgba(242, 242, 242, 1)',
  other: 'rgba(90, 158, 183, 1)',
};

const shadow = {
  title: `2px 2px 2px ${colors.primary},
    0 2px 2px ${colors.primary},
    -2px 0 2px ${colors.primary},
    0 -2px 2px ${colors.primary}`,
};

const fontSize = {
  s: '12px',
  m: '16px',
  l: '20px',
  xl: '24px',
  xxl: '32px',
};

export const theme = {
  media,
  colors,
  shadow,
  fontSize,
};
