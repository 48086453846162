import Header from 'components/Header';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme/theme';
import { MainContainer, Wrapper } from './SharedLayout.styled';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from 'components/Footer';

const SharedLayout = () => {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Header />
        <MainContainer>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </MainContainer>
        <Footer />
      </Wrapper>
      <ToastContainer position="bottom-right" autoClose={5000} closeOnClick />
    </ThemeProvider>
  );
};

export default SharedLayout;
